
import { Component, Prop } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import LocaleRepository from '@/repositories/LocaleRepository';
import { handleSave, translationsHaveChanges } from '@/helpers/TranslationHelper';
import { LocaleValues } from '@/interfaces/components/translations/LocaleValues';
import { mixins } from 'vue-class-component';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import { Button, Input, Select } from 'ant-design-vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';

@Component({
    name: 'TranslationTopBar',
    components: { Select, Input, Button, SelectOption: Select.Option },
})
// @ts-ignore
export default class TranslationTopBar extends mixins<CheckUnsavedDataMixin>(CheckUnsavedDataMixin) {
    @Prop({ default: 'hr' }) private locale!: string;
    @Prop({ default: 'sl' }) private baseLocale!: string;
    @Prop({ default: '' }) private query!: string;
    @Prop({ default: '' }) private entityName!: string;
    @Prop({ default: '' }) private attribute!: string;
    @Prop({ default: false }) private showOnlyEmpty!: boolean;
    @Prop({ default: false }) private hasUnsavedData!: boolean;
    @Prop({
        default: () => {
            return {};
        },
    })
    private translatedStrings!: LocaleValues;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get locales() {
        return LocaleRepository.getAll();
    }

    private created() {
        EventBus.$on(EventBusEvents.saveAllTranslations, (payload: any) => {
            this.onSaveAll();
        });
    }

    private get areAnyTranslationsUpdated() {
        return translationsHaveChanges(this.translatedStrings);
    }

    private async onLocaleChange(locale: string, isBaseLocale = false) {
        if (this.areAnyTranslationsUpdated && !this.confirmLeave()) {
            return;
        }

        if (isBaseLocale) {
            this.$emit('update:baseLocale', locale);
        } else {
            this.$emit('update:locale', locale);
        }

        this.$emit('onSetToFirstPageAndUpdateTranslationData');
        this.$emit('update:hasUnsavedData', false);
    }

    private async onSearch(newQuery: string) {
        if (this.areAnyTranslationsUpdated && !this.confirmLeave()) {
            return;
        }

        this.$emit('update:query', newQuery);
        this.$emit('onSetToFirstPageAndUpdateTranslationData');
    }

    private async onSaveAll() {
        this.loadingOverlay.start();
        try {
            await handleSave(this.translatedStrings, this.entityName, this.locale, this.attribute, this.baseLocale);
        } finally {
            this.loadingOverlay.stop();
        }
        this.$emit('onUpdateTranslationData');
        this.$emit('update:hasUnsavedData', false);
    }

    private async onShowOnlyEmptyChange(value: boolean) {
        if (this.areAnyTranslationsUpdated && !this.confirmLeave()) {
            return;
        }

        this.$emit('update:showOnlyEmpty', value);
        this.$emit('onSetToFirstPageAndUpdateTranslationData');
    }
}
